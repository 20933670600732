export default function Counter(props) {
  return (
    <div
      className={`${props.additionalClasses} flex justify-center items-center border border-gray-200`}
    >
      <button
        onClick={() => props.setCounter(props.counter - 1)}
        className="bg-gray-100 px-2"
      >
        -
      </button>
      <span className="px-2">{props.counter}</span>
      <button
        onClick={() => props.setCounter(props.counter + 1)}
        className="bg-gray-100 px-2"
      >
        +
      </button>
    </div>
  );
}
