import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCdbVZXFTX9Q7ND6I0puGxR6Zs0HdLmAtw",
  authDomain: "blog-3a0d5.firebaseapp.com",
  databaseURL: "https://blog-3a0d5.firebaseio.com",
  projectId: "blog-3a0d5",
  storageBucket: "blog-3a0d5.appspot.com",
  messagingSenderId: "1090983066993",
  appId: "1:1090983066993:web:dc7497725f7aa8b7594ad0",
  measurementId: "G-4L321MG63R"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);