import { useRef, useEffect, useState, useContext } from "react";
import { PlayersContext } from "../../database/playerContext";

export default function Players(props) {
  // const [players, setPlayers] = useState([]);
  const { players, setPlayers } = useContext(PlayersContext);
  const [showPlayers, toggleShowPlayers] = useState(true);
  const playerRef = useRef();

  useEffect(() => {
    setPlayers(players);
  });

  return (
    <div className="fixed top-0 left-0 flex flex-col flex-none m-4 lg:flex-row">
      <button
        className="bg-gray-300 mr-4 px-4 py-2 rounded-lg self-start"
        onClick={() => toggleShowPlayers(!showPlayers)}
      >
        Show Players
      </button>
      {showPlayers && (
        <div className="self-start mt-2 lg:mt-0">
          <input
            className="px-1 py-2 rounded-lg"
            placeholder="Enter a player:"
            ref={playerRef}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                !players.includes(playerRef.current.value)
              ) {
                setPlayers([...players, playerRef.current.value]);
                playerRef.current.value = "";
              } else if (
                e.key === "Enter" &&
                players.includes(playerRef.current.value)
              ) {
                alert("That name is already chosen, please select a new one!");
              }
            }}
          />
          <div className="flex flex-col mt-1">
            {players.map((player, index) => (
              <button
                key={index}
                onClick={() => {
                  let temp = [...players];
                  temp.splice(index, 1);
                  setPlayers(temp);
                }}
                className="text-left"
              >
                {player}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
