import { useRef, useState } from "react";

import Counter from "../Buttons/Counter";
import FormExtraChecks from "./FormExtraChecks";
import classes from "./Form.module.css";

import { db } from "../../database/firebase";
import { ref, push } from "firebase/database";

export default function Form(props) {
  const [counter, changeCounter] = useState(0);

  let submission = {
    name: "",
    question: "",
    everybody: false,
    showQuestion: false,
    diceRequired: false,
    cardRequired: false,
    randomPlayers: 0,
    improv: false,
    caliente: false,
  };
  const nameRef = useRef();
  const promptRef = useRef();
  let cardordice = "None";
  let everybody = false;
  let improv = false;
  let caliente = false;

  function submitHandler() {
    submission = {
      name: nameRef.current.value,
      question: promptRef.current.value,
      everybody: everybody === "No" ? false : true,
      showQuestion: false,
      diceRequired: cardordice === "Dice" ? true : false,
      cardRequired: cardordice === "Cards" ? true : false,
      randomPlayers: counter,
      caliente: caliente,
      improv: false,
    };
    if (submission.name !== "" && submission.question !== "") {
      push(ref(db, "submissions"), submission);
      alert("submitted!");
      promptRef.current.value = "";
      changeCounter(0);
    } else {
      alert(
        "The form has been filled out incorrectly, please fill in all fields!"
      );
    }
  }

  return (
    <div
      className={`${props.additionalClasses} ${classes.form} flex flex-col justify-start items-start overflow-y-auto bg-white rounded-lg p-4 mx-auto w-full lg:w-1/2`}
    >
      <div className="flex justify-between w-full">
        <h3 className="text-3xl">Submission Rules:</h3>
        <button onClick={() => props.toggleOverlay()} className="text-3xl">
          &times;
        </button>
      </div>
      <p className="mt-1">
        - Use <b>randomPlayer(i)</b> to insert random players from the game into
        your prompt, replacing <b>(i)</b> a number (starting at 0). Then
        increment the <b>Random Players</b> counter
        <br />
        &emsp;- Ex: If you want 2 random players in this prompt, use{" "}
        <b>randomPlayer0</b> and <b>randomPlayer1</b> in the prompt in lieu of
        their name, and increment the <b>Random Players</b> counter by 2.
        <br />- If you want to put the current player's name into the prompt,
        you can use <b>currentPlayer</b>.
      </p>
      <input
        className="w-full bg-gray-200 rounded-md p-1 mt-2"
        placeholder="Name"
        ref={nameRef}
      />
      <textarea
        rows="4"
        className="w-full bg-gray-200 rounded-md p-1 mt-2 flex-none"
        placeholder="Question or Prompt"
        ref={promptRef}
      />
      <span className="mt-2">Random Players</span>
      <Counter counter={counter} setCounter={(count) => changeCounter(count)} />
      <div className="mt-2">
        <span>Extra Requirements</span>
        <FormExtraChecks
          setActiveCheck={(choice) => {
            cardordice = choice;
          }}
          checks={["None", "Cards", "Dice"]}
        />
      </div>
      <div className="mt-2">
        <span>Everybody question</span>
        <FormExtraChecks
          setActiveCheck={(choice) => {
            everybody = choice;
          }}
          checks={["No", "Yes"]}
        />
      </div>
      {/* <div className="mt-2">
        <span>Only for MST Theater/Improv</span>
        <FormExtraChecks
          setActiveCheck={(choice) => {
            improv = choice;
          }}
          checks={["No", "Yes"]}
        />
      </div> */}
      <div className="mt-2">
        <span>Caliente</span>
        <FormExtraChecks
          setActiveCheck={(choice) => {
            caliente = choice;
          }}
          checks={["No", "Both", "Yes"]}
        />
      </div>
      <button
        onClick={() => {
          submitHandler();
        }}
        className="border bg-blue-700 text-white px-4 py-2 rounded-lg mt-4 ml-auto"
      >
        Submit
      </button>
    </div>
  );
}
