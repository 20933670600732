import React from "react";
import { useState } from "react";

import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Players from "./components/Players/Players";

import "./index.css";
import Home from "./pages/Home";
import Questions from "./pages/Questions";
import { PlayersContext } from "./database/playerContext";

export default function App() {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  window.addEventListener("resize", () => {
    // We execute the same script as before
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  const [players, setPlayers] = useState([]);
  return (
    <PlayersContext.Provider value={{ players, setPlayers }}>
      <div className="bg-blue-100 h-full">
        <Players />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/questions" element={<Questions />} />
          </Routes>
        </BrowserRouter>
      </div>
    </PlayersContext.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
