import { useEffect, useState } from "react";

export default function PlayingCard() {
  const [suits, setSuits] = useState(["hearts", "diamonds", "clubs", "spades"]);
  const [ranks, setRanks] = useState([
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    "J",
    "Q",
    "K",
    "A",
  ]);
  const [currentCard, setCurrentCard] = useState({ suit: null, rank: null });
  const [deck, setDeck] = useState([]);

  useEffect(() => {
    makeDeck();
  }, []);

  useEffect(() => {
    drawCard();
  }, [deck]);

  function makeDeck() {
    // this.currentCard = undefined;
    setDeck([]);
    for (let i = 0; i < suits.length; i++) {
      for (let j = 0; j < ranks.length; j++) {
        let card = {
          suit: suits[i],
          rank: ranks[j],
        };

        setDeck((current) => [...current, card]);
        // this.deck.push(card);
      }
    }
  }

  function drawCard() {
    if (deck.length > 0) {
      let randIndex = Math.floor(Math.random() * deck.length);
      setCurrentCard(deck.splice(randIndex, 1)[0]);
    }
  }

  function color() {
    if (currentCard.suit === "hearts" || currentCard.suit === "diamonds") {
      return "text-red-600";
    } else {
      return "text-black";
    }
  }

  function suitHTMLCharacter() {
    if (currentCard.suit === "hearts") return "\u2665";
    else if (currentCard.suit === "diamonds") return "\u25C6";
    else if (currentCard.suit === "spades") return "\u2660";
    else if (currentCard.suit === "clubs") return "\u2663";
    // if (currentCard.suit === "diamonds") return "&diams;";
    // return <>\u${currentCard.suit.toLowerCase()}</>;
    // return `&${currentCard.suit.toLowerCase()}\;`;
  }

  return (
    <div
      className={`${color()} relative border border-black bg-white rounded-md text-4xl w-48 h-64 lg:w-96 lg:h-128 lg:text-6xl`}
    >
      <div className="absolute top-0 left-0 mt-2 ml-2 leading-none">
        <div>{currentCard.rank}</div>
        <div>{suitHTMLCharacter()}</div>
      </div>
      <div className="absolute bottom-0 right-0 mb-2 mr-2 leading-none rotate-180">
        <div>{currentCard.rank}</div>
        <div>{suitHTMLCharacter()}</div>
      </div>
      <div className="absolute text-6xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:text-8xl">
        {suitHTMLCharacter()}
      </div>
    </div>
  );
}
