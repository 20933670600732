export default function Overlay(props) {
  return (
    <div
      onClick={() => {
        props.toggleOverlay();
      }}
      className={`${props.additionalClasses} absolute w-screen h-screen bg-black opacity-40`}
    ></div>
  );
}
