export default function ChangeQuestion(props) {
  return (
    <button
      onClick={() => props.clicked()}
      className={`${props.additionalClasses} ${
        props.previous ? "bg-red-500" : ""
      } ${
        props.next ? "bg-blue-500" : ""
      } w-32 py-3 text-white rounded-lg text-xl`}
    >
      {props.children}
    </button>
  );
}
