import { useEffect, useState } from "react";

export default function Dice() {
  const [diceValue, setDiceValue] = useState(null);
  function roll() {
    setDiceValue(Math.floor(Math.random() * 6) + 1);
  }

  useEffect(() => {
    roll();
  }, []);

  return (
    <div class="relative flex items-center">
      <div class="grid grid-cols-3 grid-rows-3 gap-1 border border-black bg-white rounded-lg p-1 shadow-md mr-2">
        <div
          className={`${
            diceValue > 1 ? "bg-black" : "bg-white"
          } w-4 h-4 rounded-full`}
        />
        <div className={`w-4 h-4 bg-white rounded-full`} />
        <div
          className={`${
            diceValue > 3 ? "bg-black" : "bg-white"
          } w-4 h-4 rounded-full`}
        />
        <div
          className={`${
            diceValue === 6 ? "bg-black" : "bg-white"
          } w-4 h-4 rounded-full`}
        />
        <div
          className={`${
            diceValue % 2 === 1 ? "bg-black" : "bg-white"
          } w-4 h-4 rounded-full`}
        />
        <div
          className={`${
            diceValue === 6 ? "bg-black" : "bg-white"
          } w-4 h-4 rounded-full`}
        />
        <div
          className={`${
            diceValue > 3 ? "bg-black" : "bg-white"
          } w-4 h-4 rounded-full`}
        />
        <div className={`w-4 h-4 rounded-full`} />
        <div
          className={`${
            diceValue >= 2 ? "bg-black" : "bg-white"
          } w-4 h-4 rounded-full`}
        />
      </div>
      {/* <button
        onClick={() => roll()}
        className="flex-none bg-gray-400 px-2 py-1 text-base"
      >
        Roll
      </button> */}
    </div>
  );
}
