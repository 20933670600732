import { useState } from "react";
import { Link } from "react-router-dom";
import FormContainer from "../components/Form/FormContainer";

function Home() {
  const [showForm, toggleShowForm] = useState(false);

  return (
    <div className="flex flex-col justify-center items-center h-full">
      {showForm && (
        <FormContainer
          toggleOverlay={() => {
            toggleShowForm(!showForm);
          }}
        />
      )}
      <button
        onClick={() => {
          toggleShowForm(!showForm);
        }}
        className="fixed z-0 top-0 right-0 px-4 py-2 m-4 bg-orange-400 rounded-xl"
      >
        Form
      </button>
      <div className="flex flex-col justify-center items-center -mb-24">
        <div className="flex flex-col justify-center items-center lg:flex-row">
          <Link to="/questions">
            <img
              className="w-48 mb-16 sm:w-64 lg:mr-32 lg:mb-0 lg:w-96"
              alt="questions option"
              src="images/questions.png"
            />
          </Link>
          <img
            className="w-48 sm:w-64 lg:w-96"
            alt="queens cup option"
            src="images/queen.png"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <span className="text-center text-lg font-semibold mt-16 mx-8 lg:mt-8 lg:mx-0 lg:text-2xl">
            A GAME WEBSITE BUILT FOR NERDS
          </span>
          <span className="text-center text-sm font-semibold mx-8 lg:mx-0 lg:text-lg">
            DEVELOPED BY PAUL CASSELL & DESIGNED WITH HELP FROM ARI WAGNER
          </span>
        </div>
      </div>
    </div>
  );
}

export default Home;
