import { useEffect, useState } from "react";

export default function FormExtraChecks(props) {
  const [active, setActive] = useState(props.checks[0]);

  useEffect(() => {
    props.setActiveCheck(active);
  });
  return (
    <div className={`${props.additionalClasses}`}>
      {props.checks.map((check, index) => (
        <button
          key={index}
          onClick={() => {
            setActive(check);
          }}
          className={`${
            active === check
              ? "bg-blue-500 text-white shadow-none"
              : "bg-gray-200"
          } ${index === 0 ? "rounded-tl-md rounded-bl-md" : ""} ${
            index === props.checks.length - 1
              ? "rounded-tr-md rounded-br-md"
              : ""
          } px-4 py-2 transition-all duration-200`}
        >
          {check}
        </button>
      ))}
    </div>
  );
}
