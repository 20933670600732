import { useEffect, useState, useContext } from "react";
import { onValue, ref } from "firebase/database";
import { db } from "../database/firebase";
import { PlayersContext } from "../database/playerContext";
import ChangeQuestion from "../components/Buttons/ChangeQuestion";
import Dice from "../components/Overlay/Dice";
import PlayingCard from "../components/Overlay/PlayingCard";
import ExtraRequirementContainer from "../components/Overlay/ExtraRequirementContainer";

export default function Questions(props) {
  const { players, setPlayers } = useContext(PlayersContext);
  const [showExtra, setShowExtra] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({
    index: 0,
    lastClicked: "",
  });
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);

  // Get all submissions
  useEffect(() => {
    const query = ref(db, "submissions");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      let temp = [];
      if (snapshot.exists()) {
        Object.values(data).map((submission) => {
          temp.push(submission);
        });

        return setSubmissions(temp);
      }
    });
  }, []);

  // Filter submissions from the beginning
  useEffect(() => {
    if (filteredSubmissions.length <= 0) {
      let temp = [
        ...submissions.filter(
          (submission) =>
            submission.showQuestion &&
            submission.caliente !== true &&
            submission.caliente !== "Yes" &&
            submission.caliente === "Both" &&
            players.length > submission.randomPlayers
        ),
      ];
      console.log("temp", temp);
      setFilteredSubmissions(shuffle(temp));
    }
  }, [submissions]);

  // Add/Remove questions that involve more players if number of players changes
  useEffect(() => {
    let temp = [
      ...submissions.filter(
        (submission) =>
          submission.showQuestion &&
          submission.caliente !== true &&
          submission.caliente !== "Yes" &&
          submission.caliente === "Both" &&
          players.length > submission.randomPlayers
      ),
    ];
    setFilteredSubmissions(temp);
  }, [players]);

  // Replaces currentPlayer and randomPlayer
  function filter(question) {
    let returnQuestion = question.question;
    let replacePlayers = [];
    let index = 0;

    let re = new RegExp("currentPlayer", "gi");
    returnQuestion = returnQuestion.replace(re, players[currentPlayerIndex]);

    if (question.randomPlayers > 0) {
      while (replacePlayers.length < players.length - 1) {
        index = Math.floor(Math.random() * players.length);
        if (question.everybody) {
          if (!replacePlayers.includes(index)) {
            replacePlayers.push(index);
          }
        } else {
          if (index !== currentPlayerIndex && !replacePlayers.includes(index)) {
            replacePlayers.push(index);
          }
        }
      }
      for (let i = 0; i < question.randomPlayers; i++) {
        let randomPlayer = `randomPlayer${i}`;
        let re = new RegExp(randomPlayer, "gi");
        returnQuestion = returnQuestion.replace(re, players[replacePlayers[i]]);
      }
    }
    return returnQuestion;
  }

  // Changes the question
  function changeQuestion(change) {
    if (change === "previousAll") {
      currentQuestion.index <= 0
        ? setCurrentQuestion({
            index: filteredSubmissions.length - 1,
            lastClicked: change,
          })
        : setCurrentQuestion({
            index: currentQuestion.index - 1,
            lastClicked: change,
          });
      if (
        change === "previousAll" &&
        !filteredSubmissions[currentQuestion.index].everybody
      ) {
        currentPlayerIndex <= 0
          ? setCurrentPlayerIndex(players.length - 1)
          : setCurrentPlayerIndex(currentPlayerIndex - 1);
      }
    } else if (change === "nextAll") {
      currentQuestion.index >= filteredSubmissions.length - 1
        ? setCurrentQuestion({ index: 0, lastClicked: change })
        : setCurrentQuestion({
            index: currentQuestion.index + 1,
            lastClicked: change,
          });
      if (
        change === "nextAll" &&
        !filteredSubmissions[currentQuestion.index].everybody
      ) {
        currentPlayerIndex >= players.length - 1
          ? setCurrentPlayerIndex(0)
          : setCurrentPlayerIndex(currentPlayerIndex + 1);
      }
    }
  }

  // Shuffles the prompts at the beginning
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  return (
    <div className="flex justify-center items-center h-screen">
      {players && players.length > 0 && filteredSubmissions.length > 0 && (
        <div className="flex flex-col justify-center items-center mx-16 lg:mx-64">
          {showExtra && (
            <ExtraRequirementContainer
              toggleOverlay={() => setShowExtra(false)}
            >
              {filteredSubmissions[currentQuestion.index].diceRequired ? (
                <Dice />
              ) : (
                <PlayingCard />
              )}
            </ExtraRequirementContainer>
          )}
          <h3 className="text-6xl">
            {filteredSubmissions[currentQuestion.index].everybody
              ? "Everybody"
              : players[currentPlayerIndex]}
          </h3>
          <section className="flex flex-col justify-center items-center text-2xl mt-6 lg:text-3xl">
            {filter(filteredSubmissions[currentQuestion.index])}
            {filteredSubmissions[currentQuestion.index].diceRequired && (
              <button
                onClick={() => setShowExtra(true)}
                className="px-4 py-2 mt-4 rounded-lg bg-gray-700 text-white"
              >
                Roll Dice
              </button>
            )}
            {filteredSubmissions[currentQuestion.index].cardRequired && (
              <button
                onClick={() => setShowExtra(true)}
                className="px-4 py-2 mt-4 rounded-lg bg-gray-700 text-white"
              >
                Draw Card
              </button>
            )}
            {/* <Dice /> */}
            {/* <PlayingCard /> */}
          </section>
          <section className="fixed bottom-0 mb-2 flex">
            <ChangeQuestion
              clicked={() => changeQuestion("previousAll")}
              additionalClasses="mr-4"
              previous
            >
              Previous
            </ChangeQuestion>
            <ChangeQuestion clicked={() => changeQuestion("nextAll")} next>
              Next
            </ChangeQuestion>
          </section>
        </div>
      )}
      {players && players.length <= 0 && (
        <span>Need more players to start!</span>
      )}
    </div>
  );
}
