import Overlay from "./Overlay";

export default function ExtraRequirementContainer(props) {
  return (
    <div className="absolute w-screen h-full z-10 flex justify-center items-center">
      <Overlay toggleOverlay={props.toggleOverlay} />
      <div>{props.children}</div>
    </div>
  );
}
