import Overlay from "../Overlay/Overlay";
import Form from "./Form";

export default function FormContainer(props) {
  return (
    <div className="absolute h-full flex justify-center items-center">
      <Overlay toggleOverlay={props.toggleOverlay} />
      <div>
        <Form
          additionalClasses="relative z-10"
          toggleOverlay={props.toggleOverlay}
        />
      </div>
    </div>
  );
}
